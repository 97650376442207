import ScrollTrigger from "gsap/ScrollTrigger";
import React, { useRef } from "react";
import styled from "styled-components";
import Accordion from "../Accordion";

const Section = styled.section`
  height: auto;
  width: 100vw;
  background-color: ${(props) => props.theme.text};
  position: relative;
  color: ${(props) => props.theme.body};
  overflow: hidden;
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
const Title = styled.h1`
  font-size: ${(props) => props.theme.fontxxl};
  text-transform: uppercase;
  color: ${(props) => props.theme.body};

  margin: 1rem auto;
  border-bottom: 2px solid ${(props) => props.theme.carouselColor};
  width: fit-content;

  @media (max-width: 48em) {
    font-size: ${(props) => props.theme.fontxl};
  }
`;

const Container = styled.div`
  width: 75%;
  margin: 1rem auto;

  display: flex;
  justify-content: space-between;
  align-content: center;

  @media (max-width: 64em) {
    width: 80%;
  }
  @media (max-width: 48em) {
    width: 90%;
    flex-direction: column;

    & > *:last-child {
      & > *:first-child {
        margin-top: 0;
      }
    }
  }
`;
const Box = styled.div`
  width: 45%;
  @media (max-width: 64em) {
    align-self: center;
  }
  @media (max-width: 48em) {
    width: 90%;
  }
`;

const FaqJungleRiderz = () => {
  const ref = useRef(null);

  return (
    <Section ref={ref} id="faq">
      <Title>Faq</Title>

      <Container>
        <Box>
          <Accordion
            ScrollTrigger={ScrollTrigger}
            title="What is the number of JungleRiderz NFT supplies?">
            JungleRiderz is a collection of 3,333 unique NFTs.
          </Accordion>
          <Accordion
            ScrollTrigger={ScrollTrigger}
            title="What is the cost of minting?">
            More information coming soon.
          </Accordion>
          <Accordion
            ScrollTrigger={ScrollTrigger}
            title="Where can I purchase JungleRiderz NFT?">
            You can directly Mint from our NFT website when Minting Date start.
            After completion of minting JungleRiderz NFT are available to
            purchase on OpenSea, Joepegs and Campfire. We only recommend using
            our official links found in our Discord channel.
          </Accordion>
        </Box>
        <Box>
          <Accordion ScrollTrigger={ScrollTrigger} title="When is the mint?">
            The mint is on January 14, 2023. More information follows.
          </Accordion>
          <Accordion
            ScrollTrigger={ScrollTrigger}
            title="What should I do if I need support?">
            Feel free open a ticket on our Discord server and one of our team
            member would love to assist you with your queries.
          </Accordion>
        </Box>
      </Container>
    </Section>
  );
};

export default FaqJungleRiderz;
