import Team from "../components/sections/Team";

function TeamPage() {
  return (
    <>
      <Team />
    </>
  );
}

export default TeamPage;
