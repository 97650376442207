import About from "../components/sections/About";
import Home from "../components/sections/Home";

function HomePage() {
  return (
    <>
      <Home />
      <About />
    </>
  );
}

export default HomePage;
