import React, { useEffect, useState } from "react";
import styled from "styled-components";

const Section = styled.div`
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 1rem;
  width: 100%;
  background-color: ${(props) => props.theme.body};
  display: flex;
  margin-bottom: 3rem;
`;

const SubTitle = styled.h3`
  font-size: ${(props) => props.theme.fontlg};
  text-transform: capitalize;
  color: ${(props) => `rgba(${props.theme.textRgba}, 0.6)`};
  font-weight: 600;
  margin-bottom: 1rem;
  width: 80%;
  align-self: flex-start;

  @media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontmd};
  }

  @media (max-width: 48em) {
    align-self: center;
    text-align: center;
  }
`;

const Container = styled.div`
  background: linear-gradient(#01ffc3, #01ffff);
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 0.25rem;
  columns: auto;
  width: 90px;
  @media (max-width: 40em) {
    width: 60px;
  }
`;
const Box = styled.h2`
  background: linear-gradient;
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;
  width: 100%;
  font-size: ${(props) => props.theme.fontlg};
  color: ${(props) => props.theme.text};
  letter-spacing: 0.05em;
  @media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontmd};
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
`;
const Title = styled.p`
  width: 100%;
  height: 1.5rem;
  background-color: #fff;
  font-size: ${(props) => props.theme.fontmd};
  color: ${(props) => props.theme.body};
  text-align: center;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  @media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontsm};

`;

function CountDown() {
  const [timerDays, setTimerDays] = useState(0);
  const [timerHours, setTimerHours] = useState(0);
  const [timerMinutes, setTimerMinutes] = useState(0);
  const [timerSeconds, setTimerSeconds] = useState(0);

  let interval;

  const startTimer = () => {
    const countDownDate = new Date(
      "January 22,2023 22:00:00 GMT+01:00"
    ).getTime();

    interval = setInterval(() => {
      const now = new Date().getTime();

      const distance = countDownDate - now;

      const days = Math.floor(distance / (24 * 60 * 60 * 1000));
      const hours = Math.floor(
        (distance % (24 * 60 * 60 * 1000)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (60 * 60 * 1000)) / (1000 * 60));
      const seconds = Math.floor((distance % (60 * 1000)) / 1000);

      if (distance < 0) {
        clearInterval(interval.current);
      } else {
        // Update Timer
        setTimerDays(days);
        setTimerHours(hours);
        setTimerMinutes(minutes);
        setTimerSeconds(seconds);
      }
    });
  };

  useEffect(() => {
    startTimer();
  });

  return (
    <>
      <SubTitle>NFT drop coming soon!!</SubTitle>
      <Section>
        <Container>
          <Box>{timerDays}</Box>
          <Title>Days</Title>
        </Container>
        <Container>
          <Box>{timerHours}</Box>
          <Title>Hours</Title>
        </Container>
        <Container>
          <Box>{timerMinutes}</Box>
          <Title>Min</Title>
        </Container>
        <Container>
          <Box>{timerSeconds}</Box>
          <Title>Sec</Title>
        </Container>
      </Section>
    </>
  );
}

export default CountDown;
