import ScrollTrigger from "gsap/ScrollTrigger";
import React, { useRef } from "react";
import styled from "styled-components";
import Accordion from "../Accordion";

const Section = styled.section`
  height: auto;
  width: 100vw;
  background-color: ${(props) => props.theme.text};
  position: relative;
  color: ${(props) => props.theme.body};
  overflow: hidden;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
const Title = styled.h1`
  font-size: ${(props) => props.theme.fontxxl};
  text-transform: uppercase;
  color: ${(props) => props.theme.body};

  margin: 1rem auto;
  border-bottom: 2px solid ${(props) => props.theme.carouselColor};
  width: fit-content;

  @media (max-width: 48em) {
    font-size: ${(props) => props.theme.fontxl};
  }
`;

const Container = styled.div`
  width: 75%;
  margin: 1rem auto;

  display: flex;
  justify-content: space-between;
  align-content: center;

  @media (max-width: 64em) {
    width: 80%;
  }
  @media (max-width: 48em) {
    width: 90%;
    flex-direction: column;

    & > *:last-child {
      & > *:first-child {
        margin-top: 0;
      }
    }
  }
`;
const Box = styled.div`
  width: 45%;
  @media (max-width: 64em) {
    align-self: center;
  }
  @media (max-width: 48em) {
    width: 90%;
  }
`;

const FaqGalexyz = () => {
  const ref = useRef(null);

  return (
    <Section ref={ref} id="faq">
      <Title>Faq</Title>

      <Container>
        <Box>
          <Accordion
            ScrollTrigger={ScrollTrigger}
            title="What is the number of Galexyz NFT supplies?">
            Galexyz is a collection of 1,111 unique NFTs.
          </Accordion>
          <Accordion
            ScrollTrigger={ScrollTrigger}
            title="Do I own my Galexyz NFT completely?">
            Once you mint, it is completely yours and you can show it off as the
            way you want.
          </Accordion>
          <Accordion
            ScrollTrigger={ScrollTrigger}
            title="What does minting / mint an NFT mean?">
            Minting an NFT, or non-fungible token, is publishing a unique
            digital asset on a blockchain so that it can be bought, sold, and
            traded.
          </Accordion>
        </Box>
        <Box>
          <Accordion
            ScrollTrigger={ScrollTrigger}
            title="What is the cost of minting?">
            FreeMint Ξ 0.00
          </Accordion>
          <Accordion
            ScrollTrigger={ScrollTrigger}
            title="Where can I purchase Galexyz NFT?
">
            You can directly Mint from Galexyz NFT website when Minting Date
            start. After completion of minting Galexyz NFT are available to
            purchase on OpenSea, Joepegs and Campfire. We only recommend using
            our official links found in our Discord channel.
          </Accordion>
          <Accordion
            ScrollTrigger={ScrollTrigger}
            title="What should I do if I need support?
">
            Feel free open a ticket on our Discord server and one of our team
            member would love to assist you with your queries.
          </Accordion>
        </Box>
      </Container>
    </Section>
  );
};

export default FaqGalexyz;
