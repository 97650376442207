import React, { lazy, Suspense } from "react";
import styled from "styled-components";
import Loading from "../Loading";
import Video from "../../assets/video.mp4";
import CountDown from "../CountFunction";

const CoverVideoJungleRiderz = lazy(() => import("../CoverVideoJungleRiderz"));
const TypeWriterText = lazy(() => import("../TypeWriterText"));

const Section = styled.section`
  min-height: ${(props) => `calc(100vh - ${props.theme.navHeight})`};
  width: 100vw;
  position: relative;
  background-color: ${(props) => props.theme.body};
`;

const Container = styled.div`
  width: 75%;
  min-height: 80vh;
  margin: 0 auto;
  /* background-color: lightblue; */

  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 64em) {
    width: 85%;
  }
  @media (max-width: 48em) {
    flex-direction: column-reverse;
    width: 100%;
    & > *:first-child {
      width: 100%;
      margin-top: 2rem;
    }
  }
`;
const Box = styled.div`
  z-index: 3;
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const HeroBg = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;
export const VideoBg = styled.video`
  heigth: 100%;
  -o-object-fit: fill;
  object-fit: fill;
  background: #232a34;
`;

const Home = () => {
  return (
    <Section id="home">
      <Container>
        <HeroBg>
          <VideoBg autoPlay loop muted src={Video} type="video/mp4" />
        </HeroBg>
        <Box>
          <Suspense fallback={<Loading />}>
            <CountDown />
            <TypeWriterText />
          </Suspense>
        </Box>
        <Box>
          <Suspense fallback={<Loading />}>
            <CoverVideoJungleRiderz />
          </Suspense>
        </Box>
      </Container>
    </Section>
  );
};

export default Home;
