import AboutGalexyz from "../components/sections/AboutGalexyz";
import FaqGalexyz from "../components/sections/FaqGalexyz";
import HomeGalexyz from "../components/sections/HomeGalexyz";
import RarityGalexyz from "../components/sections/RarityGalexyz";
import Showcase from "../components/sections/Showcase";

function GalexyzPage() {
  return (
    <>
      <HomeGalexyz />
      <AboutGalexyz />
      <RarityGalexyz />
      <Showcase />
      <FaqGalexyz />
    </>
  );
}

export default GalexyzPage;
