import React, { lazy, Suspense } from "react";
import styled from "styled-components";
// import CoverVideo from '../CoverVideo'
// import TypeWriterText from '../TypeWriterText'
// import RoundTextBlack from "../../assets/Rounded-Text-White-Sold-out.png";
import Loading from "../Loading";
import Video from "../../assets/video.mp4";
import CountDown from "../CountFunction";

const CoverVideoJungleRiderz = lazy(() => import("../CoverVideoJungleRiderz"));
const TypeWriterTextJungleRiderz = lazy(() =>
  import("../TypeWriterTextJungleRiderz")
);

const Section = styled.section`
  min-height: ${(props) => `calc(100vh - ${props.theme.navHeight})`};
  width: 100vw;
  position: relative;
  background-color: ${(props) => props.theme.body};
`;

const Container = styled.div`
  width: 75%;
  min-height: 80vh;
  margin: 0 auto;
  /* background-color: lightblue; */

  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 64em) {
    width: 85%;
  }
  @media (max-width: 48em) {
    flex-direction: column-reverse;
    width: 100%;
    & > *:first-child {
      width: 100%;
      margin-top: 2rem;
    }
  }
`;
const Box = styled.div`
  z-index: 3;
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
/* const rotate = keyframes`
100%{
  transform: rotate(1turn);
}
`;
const Round = styled.div`
  position: absolute;
  bottom: 2rem;
  right: 90%;
  width: 7rem;
  height: 7rem;
  border: 1px solid ${(props) => props.theme.text};
  border-radius: 50%;

  img {
    width: 100%;
    height: auto;
    animation: ${rotate} 6s linear infinite reverse;
  }
  @media (max-width: 64em) {
    width: 4rem;
    height: 4rem;
    left: none;
    right: 2rem;
    bottom: 100%;
  }
  @media (max-width: 48em) {
    right: 1rem;
  }
`; */

/* const Circle = styled.span`
  width: 3.5rem;
  height: 3.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  background-color: ${(props) => props.theme.text};
  color: ${(props) => props.theme.body};
  font-size: ${(props) => props.theme.fontxl};

  @media (max-width: 64em) {
    width: 2rem;
    height: 2rem;
    font-size: ${(props) => props.theme.fontlg};
  }
`; */
const HeroBg = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;
export const VideoBg = styled.video`
  heigth: 100%;
  -o-object-fit: fill;
  object-fit: fill;
  background: #232a34;
`;

const HomeJungleRiderz = () => {
  return (
    <Section id="home">
      <Container>
        <HeroBg>
          <VideoBg autoPlay loop muted src={Video} type="video/mp4" />
        </HeroBg>
        <Box>
          <Suspense fallback={<Loading />}>
            <CountDown />
            <TypeWriterTextJungleRiderz />
          </Suspense>
        </Box>
        <Box>
          <Suspense fallback={<Loading />}>
            <CoverVideoJungleRiderz />
          </Suspense>
        </Box>
        {/*         <Round>
            <Circle>&#x2193;</Circle>
            <img width={500} height={400} src={RoundTextBlack} alt="NFT" />
          </Round> */}
      </Container>
    </Section>
  );
};

export default HomeJungleRiderz;
