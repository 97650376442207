import GlobalStyles from "./styles/GlobalStyles";
import { dark } from "./styles/Themes";
import { ThemeProvider } from "styled-components";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navigation from "./components/Navigation";
import Footer from "./components/Footer";
import HomePage from "./pages/Home";
import GalexyzPage from "./pages/Galexyz";
import JungleRiderzPage from "./pages/JungleRiderz";
import RoadmapPage from "./pages/Roadmap";
import TeamPage from "./pages/Team";
import ScrollToTop from "./components/ScrollToTop";
import MintPage from "./pages/Mint";

function App() {
  return (
    <>
      <GlobalStyles />
      <ThemeProvider theme={dark}>
        <Router>
          <Navigation />
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/roadmap" element={<RoadmapPage />} />
            <Route path="/galexyz" element={<GalexyzPage />} />
            <Route path="/jungleriderz" element={<JungleRiderzPage />} />
            <Route path="/team" element={<TeamPage />} />
            <Route path="/mint/jungleriderz" element={<MintPage />} />
          </Routes>
          <Footer />
        </Router>
        <ScrollToTop />
      </ThemeProvider>
    </>
  );
}

export default App;
