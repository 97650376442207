import * as React from "react";

const Discord = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 30}
    height={props.height || 30}
    {...props}>
    <path d="M25.396 5.463a24.739 24.739 0 0 0 -6.106 -1.894 0.092 0.092 0 0 0 -0.099 0.046c-0.263 0.469 -0.555 1.08 -0.76 1.563a22.837 22.837 0 0 0 -6.859 0 15.8 15.8 0 0 0 -0.771 -1.563 0.096 0.096 0 0 0 -0.099 -0.046A24.67 24.67 0 0 0 4.596 5.463a0.088 0.088 0 0 0 -0.04 0.034C0.666 11.307 -0.4 16.975 0.124 22.571a0.103 0.103 0 0 0 0.039 0.071 24.875 24.875 0 0 0 7.491 3.787 0.098 0.098 0 0 0 0.105 -0.035 17.613 17.613 0 0 0 1.532 -2.493 0.095 0.095 0 0 0 -0.051 -0.133 16.384 16.384 0 0 1 -2.34 -1.115 0.096 0.096 0 0 1 -0.01 -0.16 12.75 12.75 0 0 0 0.465 -0.365 0.092 0.092 0 0 1 0.096 -0.013c4.91 2.241 10.225 2.241 15.077 0a0.092 0.092 0 0 1 0.098 0.013c0.15 0.122 0.307 0.247 0.466 0.365a0.096 0.096 0 0 1 -0.007 0.159 15.374 15.374 0 0 1 -2.341 1.115 0.096 0.096 0 0 0 -0.051 0.134c0.45 0.872 0.965 1.703 1.531 2.491a0.095 0.095 0 0 0 0.105 0.035 24.799 24.799 0 0 0 7.502 -3.787 0.096 0.096 0 0 0 0.04 -0.068c0.625 -6.471 -1.047 -12.092 -4.436 -17.075a0.076 0.076 0 0 0 -0.039 -0.037zM10.025 19.163c-1.479 0 -2.696 -1.356 -2.696 -3.024 0 -1.666 1.195 -3.024 2.696 -3.024 1.512 0 2.72 1.37 2.696 3.025 0 1.666 -1.195 3.022 -2.696 3.022zm9.969 0c-1.479 0 -2.696 -1.356 -2.696 -3.024 0 -1.666 1.194 -3.024 2.696 -3.024 1.512 0 2.72 1.37 2.696 3.025 0 1.666 -1.182 3.022 -2.696 3.022z" />
  </svg>
);

export default Discord;
