import FaqJungleRiderz from "../components/sections/FaqJungleRiderz";
import React from "react";
import styled from "styled-components";
import CountDownLight from "../components/CountFunctionLight";

function MintPage() {
  const Box = styled.div`
    margin-top: 3rem;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
  `;
  return (
    <>
      <Box>
        {/*  <CountDownLight /> */}
        <iframe
          src="https://gateway.ipfscdn.io/ipfs/QmbqEq5EQLx1aPurZFreM246fsKeawfpKDT8uzguAHAikr/erc721.html?contract=0xC838a05ffcD1a4e54eCe51b3a8988487Dc1FA3a5&chainId=43114"
          width="600px"
          height="600px"
          className="iframe"
          max-width="100%"
          frameborder="0"
          display="center"
          title="Mint Jungle Riderz">
          Your browser does not support iframes.
        </iframe>
      </Box>
      <FaqJungleRiderz />
    </>
  );
}

export default MintPage;
