import AboutJungleRiderz from "../components/sections/AboutJungleRiderz";
import HomeJungleRiderz from "../components/sections/HomeJungleRiderz";
import RarityJungleRiderz from "../components/sections/RarityJungleRiderz";
import FaqJungleRiderz from "../components/sections/FaqJungleRiderz";

function JungleRiderzPage() {
  return (
    <>
      <HomeJungleRiderz />
      <AboutJungleRiderz />
      <RarityJungleRiderz />
      <FaqJungleRiderz />
    </>
  );
}

export default JungleRiderzPage;
