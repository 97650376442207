import Roadmap from "../components/sections/Roadmap";

function RoadmapPage() {
  return (
    <>
      <Roadmap />
    </>
  );
}

export default RoadmapPage;
