import React, { lazy, Suspense } from "react";
import styled from "styled-components";

import img1 from "../../assets/rarityJungleRiderz/Position1.png";
import img2 from "../../assets/rarityJungleRiderz/Position2.png";
import img3 from "../../assets/rarityJungleRiderz/Position3.png";
import img4 from "../../assets/rarityJungleRiderz/Position4.png";
import img5 from "../../assets/rarityJungleRiderz/Position5.png";
import img6 from "../../assets/rarityJungleRiderz/Position6.png";
import img7 from "../../assets/rarityJungleRiderz/Position7.png";
import img8 from "../../assets/rarityJungleRiderz/Backgrounds.png";
import img9 from "../../assets/rarityJungleRiderz/Eyes.png";
import img10 from "../../assets/rarityJungleRiderz/Mouth.png";
import img11 from "../../assets/rarityJungleRiderz/Accessoires.png";
import img12 from "../../assets/rarityJungleRiderz/Wings.png";
import img13 from "../../assets/rarityJungleRiderz/Heads.png";
import img14 from "../../assets/rarityJungleRiderz/Position8.png";
import Loading from "../Loading";
// import ConfettiComponent from '../Confetti';

const ConfettiComponent = lazy(() => import("../Confetti"));

const Section = styled.section`
  min-height: 100vh;
  width: 100vw;
  background-color: ${(props) => props.theme.body};
  position: relative;
  overflow: hidden;
`;
const Title = styled.h1`
  font-size: ${(props) => props.theme.fontxxl};
  text-transform: capitalize;
  color: ${(props) => props.theme.text};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem auto;
  border-bottom: 2px solid ${(props) => props.theme.text};
  width: fit-content;

  @media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontxxl};
  }
`;
const SubTitle = styled.h3`
  font-size: ${(props) => props.theme.fontlg};
  text-transform: capitalize;
  color: ${(props) => props.theme.text};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem auto;
  width: fit-content;

  @media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontlg};
  }
`;

const SubText = styled.p`
  font-size: ${(props) => props.theme.fontmd};
  color: ${(props) => props.theme.text};
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  text-align: center
  width: 75%;
  margin: 3rem auto;
  font-weight: 400;
  @media (max-width: 64em) {
    width: 75%;
    font-size: ${(props) => props.theme.fontmd};
    text-align: center
  }
  @media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontmd};
    text-align: center
  }
  @media (max-width: 30em) {
    font-size: ${(props) => props.theme.fontsm};
    text-align: center
  }
`;

const Container = styled.div`
  width: 75%;
  margin: 2rem auto;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  @media (max-width: 64em) {
    width: 80%;
  }
  @media (max-width: 48em) {
    width: 90%;
    justify-content: center;
  }
`;

const Item = styled.div`
  width: calc(20rem - 4vw);
  padding: 1rem 0;
  color: ${(props) => props.theme.body};
  margin: 2rem 1rem;
  position: relative;
  z-index: 5;

  backdrop-filter: blur(4px);

  border: 2px solid ${(props) => props.theme.text};
  border-radius: 20px;

  &:hover {
    img {
      transform: translateY(-2rem) scale(1.2);
    }
  }

  @media (max-width: 30em) {
    width: 70vw;
  }
`;

const ImageContainer = styled.div`
  width: 80%;
  margin: 0 auto;
  background-color: ${(props) => props.theme.body};
  border: 1px solid ${(props) => props.theme.body};
  padding: 1rem;

  border-radius: 20px;
  cursor: pointer;

  img {
    width: 100%;
    height: auto;
    transition: all 0.3s ease;
    border-radius: 20px;
  }
`;

const Name = styled.h2`
  font-size: ${(props) => props.theme.fontlg};
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  color: ${(props) => props.theme.text};
  margin-top: 1rem;
`;

const CategoryName = styled.h2`
  font-size: ${(props) => props.theme.fontxl};
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  color: ${(props) => props.theme.text};
  margin-top: 1rem;
`;

const Position = styled.h2`
  font-size: ${(props) => props.theme.fontmd};
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  color: ${(props) => `rgba(${props.theme.textRgba},0.9)`};
  font-weight: 400;
`;

const MemberComponent = ({ img, name = " ", position = " " }) => {
  return (
    <Item>
      <ImageContainer>
        <img width={500} height={400} src={img} alt={name} />
      </ImageContainer>
      <Name>{name}</Name>
      <Position>{position}</Position>
    </Item>
  );
};

const CategoryComponent = ({ img, name = " ", position = " " }) => {
  return (
    <Item>
      <ImageContainer>
        <img width={500} height={400} src={img} alt={name} />
      </ImageContainer>
      <CategoryName>{name}</CategoryName>
      <Position>{position}</Position>
    </Item>
  );
};

const RarityJungleRiderz = () => {
  return (
    <Section id="rarityGalexyz">
      <Suspense fallback={<Loading />}>
        <ConfettiComponent />{" "}
      </Suspense>
      <Title>Rarity</Title>
      <SubText>
        The JungleRiderz NFTs are algorithmically generated based on 8 unique
        positions and rarities across categories.
      </SubText>
      <SubTitle>Rarity Positions</SubTitle>
      <Container>
        <MemberComponent img={img2} name="Kicker" position="Quantity: 808" />
        <MemberComponent img={img7} name="Safety" position="Quantity: 700" />
        <MemberComponent
          img={img3}
          name="Wide Receiver"
          position="Quantity: 600"
        />
        <MemberComponent
          img={img4}
          name="Cornerback"
          position="Quantity: 500"
        />
        <MemberComponent
          img={img1}
          name="Running Back"
          position="Quantity: 375"
        />
        <MemberComponent
          img={img6}
          name="Linebacker"
          position="Quantity: 200"
        />
        <MemberComponent
          img={img5}
          name="Quarterback"
          position="Quantity: 100"
        />
        <MemberComponent img={img14} name="Legendary" position="Quantity: 50" />
      </Container>
      <SubTitle>Trait Categories</SubTitle>
      <Container>
        <CategoryComponent img={img8} name="100+" position="Backgrounds" />
        <CategoryComponent img={img13} name="8" position="Heads" />
        <CategoryComponent img={img9} name="31" position="Eyes" />
        <CategoryComponent img={img10} name="26" position="Mouths" />
        <CategoryComponent img={img12} name="15" position="Wings" />
        <CategoryComponent img={img11} name="13" position="Accessoires" />
      </Container>
    </Section>
  );
};

export default RarityJungleRiderz;
